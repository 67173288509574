@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.box {
  width: 800;
  height: 600;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.LinearerVerlauf1 {
  position: absolute;
  width: 850px;
  height: 1040px;
  left: 382px;
  top: -160px;
}

.KompetenzzentreneStandards2 {
  position: absolute;
  width: 175px;
  left: 35px;
  top: 15px;
}

#container {
  margin: 20px 480px;
  width: 700px;
  height: 500px;
  border: 1px solid black;
}
.linear {
  height: 1000px;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  margin: -150px -260px;
  background: url(/static/media/linie.2c6ca9fb.png);
}

label[for="secondname"] {
  display: none;
}

.kreis {
  height: 1000px;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  margin: -150px -260px;
  background: url(/static/media/kreis.1dcc5344.png);
}

.para {
  width: 100%;
  line-height: 1.6;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #2c2e34;
}

.Linearwirtschaft {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  color: #2c2e34;
}

.Kreislaufwirtschaft {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  color: #2c2e34;
}

.radio-button-1 {
  position: absolute;
  left: 313px;
  top: 70px;
}

.radio-button-2 {
  position: absolute;
  left: 313px;
  top: 94px;
}

.KlickeLine1 {
  width: 138px;
  height: 44px;

  font-size: 11px;
  color: #2c2e34;

  font-family: "Noto Sans";
  font-style: normal;
  font-weight: normal;
}

.intro {
  position: absolute;
  width: 60px;
  height: 25px;
  left: 1220px;
  top: 207px;

  font-family: Noto Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 18px;
  color: #b93924;
}

.ecodesign {
  position: absolute;
  background-color: transparent;
  border: transparent;
  width: 120px;
  height: 20px;
  left: 1160px;
  top: 285px;

  font-family: Noto Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 18px;
  color: #2c2e34;
}

.strategien {
  position: absolute;
  background-color: transparent;
  border: transparent;
  width: 99px;
  height: 24px;
  left: 1168px;
  top: 348px;

  font-family: Noto Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 18px;
  color: #2c2e34;
}

.partnerschaften {
  position: absolute;
  /*border-color: transparent;*/
  /*background-color: transparent;*/
  width: 157px;
  height: 24px;
  left: 1120px;
  top: 415px;

  font-family: Noto Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 18px;
  color: #2c2e34;
}

.Ressourcen {
  position: absolute;
  border-color: transparent;
  background-color: transparent;
  width: 202px;
  height: 177px;
  left: 528px;
  top: 44px;
}

.Design {
  position: absolute;
  border-color: transparent;
  background-color: transparent;
  width: 140px;
  height: 95px;
  left: 734px;
  top: 89px;
  -webkit-transform: rotate(-20deg);
          transform: rotate(-20deg);
}

.Produktion {
  position: absolute;
  border-color: transparent;
  background-color: transparent;
  width: 120px;
  height: 110px;
  left: 932px;
  top: 99px;
}

.Vertrieb {
  position: absolute;
  border-color: transparent;
  background-color: transparent;
  width: 110px;
  height: 80px;
  left: 1020px;
  top: 255px;
}

.Nutzung {
  position: absolute;
  border-color: transparent;
  background-color: transparent;
  width: 96px;
  height: 88px;
  left: 958px;
  top: 370px;
}

.Nutzungsende {
  position: absolute;
  border-color: transparent;
  background-color: transparent;
  width: 159px;
  height: 83px;
  left: 759px;
  top: 419px;
}

.Entsorgung {
  position: absolute;
  border-color: transparent;
  background-color: transparent;
  width: 194px;
  height: 93px;
  left: 538px;
  top: 409px;
}

.reduce {
  position: absolute;
  border-color: transparent;
  background-color: red;
  width: 158px;
  height: 54px;
  left: 697px;
  top: 44px;
  -webkit-transform: rotate(-20deg);
          transform: rotate(-20deg);
}

.recycle {
  position: absolute;
  border-color: transparent;
  background-color: red;
  width: 77px;
  height: 174px;
  left: 646px;
  top: 222px;
}

.reuseprod {
  position: absolute;
  border-color: transparent;
  background-color: red;
  width: 194px;
  height: 51px;
  left: 779px;
  top: 282px;
  -webkit-transform: rotate(-70deg);
          transform: rotate(-70deg);
}

.reuse {
  position: absolute;
  border-color: transparent;
  background-color: red;
  width: 160px;
  height: 48px;
  left: 858px;
  top: 328px;
  -webkit-transform: rotate(-50deg);
          transform: rotate(-50deg);
}

.repair {
  position: absolute;
  border-color: transparent;
  background-color: red;
  width: 105px;
  height: 47px;
  left: 995px;
  top: 430px;
  -webkit-transform: rotate(-40deg);
          transform: rotate(-40deg);
}

